import Litepicker from 'litepicker/dist/nocss/litepicker.umd';

const setupDateRangePicker = () => {
    const dateRangeEl = document.querySelector('#daterange');
    const locale = dateRangeEl.attributes.locale?.value || 'en-GB';
    const clearText = dateRangeEl.attributes.clear_text?.value || 'Clear';

    if (!dateRangeEl) {
      return;
    }

    new Litepicker({
      element: dateRangeEl,
      singleMode: false,
      format: 'DD/MM/YYYY',
      lang: locale,
      resetButton: () => {
        let btn = document.createElement('button');
        btn.classList.add('cancel-btn')
        btn.innerText = clearText;
        btn.addEventListener('click', (evt) => {
          evt.preventDefault();
        });

        return btn;
      },
      setup: (picker) => {
        picker.on('button:apply', () => {
          dateRangeEl.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        });
        picker.on('button:cancel', () => {
          dateRangeEl.val('');
        })
      },
    });
  };

  document.addEventListener('DOMContentLoaded', () => {
    setupDateRangePicker();
  });
